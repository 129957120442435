import erc20Abi from "../abi/erc20.json";
import MintSOL from "../abi/fireOgv2.json";

let CONTRACTS
// public
CONTRACTS = {
    usdt: {address: "0x55d398326f99059fF775485246999027B3197955", abi: erc20Abi},
    Point: {address: "0x3Ef65bE28e674b2Db8df108604199580B7C9e5Dc", abi: erc20Abi},
    MintSOL: {address: "0x01c5CeA8d7B1c6E16421FB17211c18028b5cB176", abi: MintSOL},
};
// bnb test
// CONTRACTS = {
//     usdt: {address: "0xC89C114d3A66cbbBbe7B1F630FD1A36bf7Fd992f", abi: erc20Abi},
//     Point: {address: "0x5E6e649CC4e6793A1B4A227c7f961f048919566A", abi: erc20Abi},
//     MintSOL: {address: "0x695a1280C12875A8cf21c2Fc8162a2399465c51a", abi: MintSOL},
//
// };
export default CONTRACTS
